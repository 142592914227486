import React, { Suspense } from "react"
// import { LoadingPane } from "@/component/share/loading"
import { Tabs } from "antd"
import { TabsProps } from "antd/es/tabs"
import "antd/lib/tabs/style/css"
import classnames from "../../../utils/classnames"
import "./index.less"

const { TabPane } = Tabs

interface ZenTabbsProps extends TabsProps {
  defaultActive?: string | number
  active: string | number
  className?: string
  paneClassName?: string
  style?: React.CSSProperties
  paneStyle?: React.CSSProperties
  tabs: {
    name: string
    key?: string
    component: React.ReactNode
  }[]
}

export default function ZenTabs({
  defaultActive,
  active,
  tabs,
  className,
  paneClassName,
  style,
  paneStyle,
  ...rest
}: ZenTabbsProps) {
  const useKey = tabs.every(item => !!item.key)
  return tabs.length ? (
    <div className={classnames("zen-tabs", className)} style={style}>
      <Tabs
        defaultActiveKey={defaultActive ? `${defaultActive}` : undefined}
        activeKey={`${active}`}
        tabBarGutter={8}
        tabBarStyle={{ marginBottom: 0 }}
        {...rest}
      >
        {tabs.map((tab, i) => (
          <TabPane
            tab={tab.name}
            key={`${useKey ? tab.key : i}`}
            className={paneClassName}
            style={paneStyle}
          >
            {tab.component}

            {/* <Suspense fallback={<LoadingPane />}>{tab.component}</Suspense> */}
          </TabPane>
        ))}
      </Tabs>
    </div>
  ) : null
}
