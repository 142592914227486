import React from 'react'
import Checkbox from './checkbox'
const loadText = require('src/utils').loadText
const text = loadText('form')
import { lang } from '../../utils'
import { clone } from 'ramda'
const RequiredSuffix = (v) => {
  return React.createElement('span', {
    children: '*',
    className: 'form-plus-required',
    // style: {
    //   fontSize: "24px",
    //   position: "absolute",
    //   right: "16px",
    //   top: "16px",
    //   // transform: "translateY(-50%)",
    //   zIndex: "999",
    //   color: "#0CA8FD",
    //   lineHeight: 1,
    // },
  })
}
const industryEN = [
  'Blockchain / Crypto',
  'Gaming',
  'SaaS / Software',
  'E-Commerce',
  'Financial / Fintech',
  'Streaming / Video / Online education',
  'Media / Advertising / Social media',
  'Cloud platform / CDN',
  'Data Center / Telecom',
  'Security (Network)',
  'IT Services',
  'Enterprise / Other',
]
const industryZH = [
  '区块链',
  '游戏',
  '软件/SaaS',
  '电商',
  '金融/金融服务',
  '流媒体/视频/在线教育',
  '媒体/广告/社交媒体',
  '云计算平台/CDN',
  '数据中心/电信/通讯类',
  '网络安全',
  'IT/计算机/互联网',
  '其他/政企/制造业/医疗',
]
const jobTitleEN = [
  'IT / Operation Manager / Director',
  'CIO / CTO',
  'CEO / COO',
  'Project Administrator / Manager / Director',
  'Developer / Programmer / Engineer',
  'Founder / Co-founder',
  'Solutions / System Architect',
  'Sales/Marketing Manager / Director',
  'Purchasing / BD Manager/ Director',
  'Academics / Researchers',
  'Product Manager / Director',
  'Other',
]
const jobTitleZH = [
  'IT/运维经理/总监',
  'CIO/CTO',
  'CEO/COO',
  '项目主管/经理/总监',
  '开发/工程师',
  '创始人/合伙人',
  '解决方案/系统架构师',
  '销售/市场营销 经理/总监',
  '采购/商务经理/总监',
  '学术/研究人员',
  '产品经理/总监',
  '其他',
]
const email = {
  type: 'item',
  id: 'email',
  // customClassName: "xxxx",
  label: '',
  componentType: {
    type: 'input',
    params: {
      name: text.field.email,
      // suffix: RequiredSuffix,
    },
  },
}
const serviceLocation = {
  type: 'item',
  id: 'serviceLocation',
  // customClassName: "xxxx",
  label: '',
  componentType: {
    type: 'input',
    params: {
      name: text.field.serviceLocation,
      // suffix: RequiredSuffix,
    },
  },
}
const phone = {
  type: 'item',
  id: 'phone',
  label: '',
  componentType: {
    type: 'input',
    params: {
      name: text.field.phone,
    },
  },
}
const invitedBy = {
  type: 'item',
  id: 'invitedBy',
  label: '',
  componentType: {
    type: 'input',
    params: {
      name: 'Invited By',
    },
  },
}
const eventName = {
  type: 'item',
  id: 'eventName',
  label: '',
  componentType: {
    type: 'input',
    params: {
      disabled: true,
      name: text.field.eventName,
    },
  },
}
const location = {
  type: 'item',
  id: 'location',
  label: '',
  componentType: {
    type: 'select',
    params: {
      placeholder: text.field.location,
    },
  },
}
const role = {
  type: 'item',
  id: 'role',
  label: '',
  componentType: {
    type: 'select',
    params: {
      placeholder: 'Role',
    },
  },
}
const deployTime = {
  type: 'item',
  id: 'deployTime',
  label: '',
  componentType: {
    type: 'select',
    params: {
      placeholder: text.field.deployTime,
      allowClear: true,
    },
  },
}
const supportProduct = {
  type: 'item',
  id: '00N3h00000IJ6xq',
  label: '',
  componentType: {
    type: 'select',
    params: {
      placeholder: lang === 'en' ? 'Product' : '产品',
      allowClear: true,
    },
  },
}

const meetingTime = {
  type: 'item',
  id: 'meetingTime',
  label: '',
  componentType: {
    type: 'datePicker',
    params: {
      showTime: true,
      placeholder: text.field.meetingTime,
      style: { width: '100%', height: 48 },
    },
  },
}
const firstName = {
  type: 'item',
  id: 'firstName',
  label: '',
  componentType: {
    type: 'input',
    params: {
      name: text.field.firstName,
      // suffix: RequiredSuffix,
    },
  },
}
const lastName = {
  type: 'item',
  id: 'lastName',
  label: '',
  componentType: {
    type: 'input',
    params: {
      name: text.field.lastName,
      // suffix: RequiredSuffix,
    },
  },
}
const name = {
  type: 'item',
  id: 'name',
  label: '',
  componentType: {
    type: 'input',
    params: {
      // name: text.field.name,
      name: lang === 'en' ? 'Name' : '名称',
      // suffix: RequiredSuffix,
    },
  },
}
const chineseName = {
  type: 'item',
  id: 'firstName',
  label: '',
  componentType: {
    type: 'input',
    params: {
      // name: text.field.name,
      name: lang === 'en' ? 'Name' : '姓名',
      // suffix: RequiredSuffix,
    },
  },
}
const company = {
  type: 'item',
  id: 'company',
  label: '',
  componentType: {
    type: 'input',
    params: {
      name: text.field.company,
      // suffix: RequiredSuffix,
    },
  },
}
// const a = {
//   type: "item",
//   id: "a",
//   label: "",
//   componentType: {
//     type: "input",
//     params: {
//       name: "A Location",
//     },
//   },
// }
// const z = {
//   type: "item",
//   id: "z",
//   label: "",
//   componentType: {
//     type: "input",
//     params: {
//       name: "Z Location",
//     },
//   },
// }
// const bandwidth = {
//   type: "item",
//   id: "z",
//   label: "",
//   componentType: {
//     type: "input",
//     params: {
//       name: "Z Location",
//     },
//   },
// }
const getInputObj = ({ id, label }: { id: string; label: string }) => ({
  type: 'item',
  id,
  label: '',
  componentType: {
    type: 'input',
    params: {
      name: label,
    },
  },
})
const website = {
  type: 'item',
  id: 'website',
  label: '',
  componentType: {
    type: 'input',
    params: {
      name: text.field.website,
    },
  },
}
const helpMsg = {
  type: 'item',
  id: 'helpMsg',
  label: '',
  componentType: {
    type: 'textarea',
    params: {
      placeholder: text.field.helpMsg,
      style: { height: 120 },
    },
  },
}
const description = {
  type: 'item',
  id: 'description',
  label: '',
  componentType: {
    type: 'textarea',
    params: {
      placeholder: text.field.helpMsg,
      style: { height: 120 },
    },
  },
}
const subject = {
  type: 'item',
  id: 'subject',
  label: '',
  componentType: {
    type: 'textarea',
    params: {
      placeholder: lang === 'en' ? 'Subject' : '主题',

      style: { height: 120 },
    },
  },
}
const comment = {
  type: 'item',
  id: 'comment',
  label: '',
  componentType: {
    type: 'textarea',
    params: {
      placeholder: text.field.comment,
      style: { height: 120 },
    },
  },
}
// const consent = {
//   type: "item",
//   id: "consent",
//   label: "xxx",
//   componentType: {
//     type: "custom",
//     content: () => <div>{text.field.consent}</div>,
//   },
// }
const job = {
  type: 'item',
  id: 'jobTitle',
  label: '',
  componentType: {
    type: 'select',
    params: {
      placeholder: text.field.jobTitle,
      // suffix: RequiredSuffix,
    },
  },
}
const interested = {
  type: 'item',
  id: 'interested',
  label: '',
  componentType: {
    type: 'custom',
    content: Checkbox,
  },
}
const hasOverseas = {
  type: 'item',
  id: 'overseas',
  label: '',
  componentType: {
    type: 'custom',
    content: Checkbox,
  },
}
// const details = {
//   type: "item",
//   id: "details",
//   label: "",

//   componentType: {
//     type: "textarea",
//     params: {
//       placeholder: "Details",
//       style: { width: "100%", height: 120 },
//     },
//   },
// }
const industry = {
  // type: "item",
  // id: "industry",
  // label: "",
  // componentType: {
  //   type: "input",
  //   params: {
  //     name: text.field.industry,
  //     // suffix: RequiredSuffix,
  //   },
  // },
  type: 'item',
  id: 'industry',
  label: '',
  componentType: {
    type: 'select',
    params: {
      placeholder: text.field.industry,
      allowClear: true,
    },
  },
}
const heardFromWhere = {
  // type: "item",
  // id: "industry",
  // label: "",
  // componentType: {
  //   type: "input",
  //   params: {
  //     name: text.field.industry,
  //     // suffix: RequiredSuffix,
  //   },
  // },
  type: 'item',
  id: 'heardFromWhere',
  label: '',
  componentType: {
    type: 'select',
    params: {
      placeholder:
        lang === 'en'
          ? 'How did you learn about Zenlayer?'
          : '你从哪里知道Zenlayer',
      mode: 'multiple',
      // allowClear: true,
    },
  },
}
const textRequired = (val) => {
  if (!val) {
    throw text.validation.inputRequired
  }
}
const selectRequired = (val) => {
  if (!val || val.length === 0) {
    throw text.validation.selectRequired
  }
}
export const emailValidator = (val: string) => {
  // if (!val) {
  //   throw text.validation.inputRequired
  // }
  const emailReg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  if (!emailReg.test(val)) {
    throw text.validation.invalidEmail
  }
}
const customRule = {
  email: [emailValidator],
  phone: [
    (val: string) => {
      if (!val) {
        return
      }
      // const phoneReg = /^\d*$/
      // if (!phoneReg.test(val)) {
      //   throw text.validation.invalidPhone
      // }
    },
  ],
}

const sourceData = {
  consent: {
    defaultValue: false,
  },
  heardFromWhere: {
    data: (lang === 'en'
      ? [
          'Search',
          'Social media',
          'Email',
          'Articles / eBook / Whitepaper',
          'Events',
          'Referral',
          'Others',
        ]
      : [
          '搜索',
          '社交媒体',
          '电子邮件',
          '文章 / 电子书 / 白皮书',
          '线下活动',
          '他人推荐',
          '其他',
        ]
    ).map((key) => ({
      label: key,
      value: key,
    })),
    // defaultValue: [],
  },
  deployTime: {
    data: (lang === 'en'
      ? ['Immediately', 'End of month', 'End of quarter', 'Uncertain']
      : ['立即部署', '月末', '季末', '不确定']
    ).map((key) => ({
      label: key,
      value: key,
    })),
  },
  interested: {
    data: [
      'Cloud Networking',
      'IP Transit',
      'Bare Metal Cloud',
      'Content Delivery Network',
      'Zenlayer Global Accelerator',
      'Edge Data Center Services',
    ].map((key) => ({ label: key, value: key })),
  },
  overseas: {
    data: ['是', '否'].map((key) => ({ label: key, value: key })),
  },
  industry: {
    data: (lang === 'en' ? industryEN : industryZH).map((key) => ({
      label: key,
      value: key,
    })),
  },
  jobTitle: {
    data: (lang === 'en' ? jobTitleEN : jobTitleZH).map((key) => ({
      label: key,
      value: key,
    })),
  },
  role: {
    data: [
      'VP/Head - Business',
      'VP/Head - IT',
      'Manager - IT/Business',
      'Executive - IT/ Business',
      'Other',
    ].map((key) => ({ label: key, value: key })),
  },

  '00N3h00000IJ6xq': {
    data: [
      'Zenlayer IP Transit',
      'Zenlayer Managed Hosting',
      'Zenlayer Edge Computing Network',
      'Zenlayer Colocation',
      'Zenlayer Cloud Networking',
      'Zenlayer DDoS Protection',
      'Zenlayer Bare Metal Cloud',
      'Zenlayer-OSS(Portal)',
      'Others',
    ].map((key) => ({ label: key, value: key })),
  },
  location: {
    data: (lang === 'en'
      ? ['Los Angeles', 'San Francisco', 'Dallas', 'Washington DC', 'Online']
      : ['洛杉矶', '旧金山', '达拉斯', '华盛顿特区', '线上']
    ).map((key) => ({ label: key, value: key })),
  },
}

const generateForm = (
  layout,
  { buttonText, required, submitText, canNotSubmitWhen = undefined }
) => {
  let rule = clone(customRule)
  let res = []
  const requiredMap = new Set(required)
  layout.forEach((row) => {
    row.forEach((item, idx) => {
      let newItem = clone(item)
      newItem.customClassName = `col-${24 / row.length} col-idx-${idx}`
      if (requiredMap.has(newItem.id)) {
        const required =
          newItem.componentType.type === 'input' ? textRequired : selectRequired
        if (rule[newItem.id]) {
          rule[newItem.id] = [required].concat(rule[newItem.id])
        } else {
          rule[newItem.id] = [required]
        }
        if (newItem.componentType.type === 'input') {
          newItem.componentType.params.suffix = RequiredSuffix
        }
      }
      res.push(newItem)
    })
  })
  return {
    canNotSubmitWhen,
    buttonText,
    submitText,
    config: {
      form: res,
      customRule: rule,
      sourceData,
    },
  }
}

const partnerForm = generateForm(
  [
    [email, phone],
    [industry],
    [heardFromWhere],
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    [company, website],
    [comment],
    // [consent],
  ],
  {
    buttonText: text.buttonText.partner,
    submitText: text.submitText.partner,
    required:
      lang === 'zh'
        ? ['email', 'firstName', 'company', 'industry', 'heardFromWhere']
        : [
            'email',
            'firstName',
            'lastName',
            'company',
            'website',
            'industry',
            'heardFromWhere',
          ],
  }
)
const lpForm = generateForm(
  [
    [company, email],
    [industry],
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    // [firstName, lastName],
    [job, phone],
    // [interested],
  ],
  {
    buttonText: text.title.consultation,
    submitText: text.submitText.partner,
    required:
      lang === 'zh'
        ? ['company', 'email', 'firstName', 'industry']
        : [
            'company',
            'email',
            'firstName',
            'lastName',
            'interested',
            'industry',
          ],
  }
)
const requestForm = generateForm(
  [
    [email, phone],
    [industry],
    [helpMsg],
    [serviceLocation],
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    [company, website],
    // [consent],
  ],
  {
    required:
      lang === 'zh'
        ? ['email', 'industry', 'firstName', 'company']
        : [
            'email',
            'serviceLocation',
            'industry',
            'firstName',
            'lastName',
            'company',
          ],
    buttonText: text.title.request,
    submitText: text.submitText.request,
  }
)

const ebookForm = generateForm(
  [
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    [industry, company],
    [email, phone],
  ],
  {
    required:
      lang === 'zh'
        ? ['email', 'serviceLocation', 'firstName', 'company', 'industry']
        : [
            'email',
            'serviceLocation',
            'firstName',
            'lastName',
            'company',
            'industry',
          ],
    buttonText: text.title.ebook,
    submitText: text.submitText.request,
  }
)
const consultationForm = generateForm(
  [
    [email, phone],
    [industry, job],
    [heardFromWhere],
    [deployTime],
    [helpMsg],
    [company, website],
    // [firstName, lastName],
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    // [consent],
  ],
  {
    buttonText: text.buttonText.consultation,
    submitText: text.submitText.consultation,
    required:
      lang === 'zh'
        ? [
            'email',
            'firstName',
            'phone',
            'company',
            'industry',
            'jobTitle',
            'heardFromWhere',
          ]
        : [
            'email',
            'firstName',
            'jobTitle',
            'helpMsg',
            'lastName',
            'company',
            'deployTime',
            'industry',
            'heardFromWhere',
          ],
  }
)

const supportForm = generateForm(
  [
    [email, phone],
    [industry, supportProduct],
    [name],
    [description],
    [subject],
    // [consent],
  ],
  {
    required: ['email', 'phone', 'name', 'industry'],
    buttonText: text.title.support,
    submitText: text.submitText.support,
  }
)
const saleForm = generateForm(
  [
    [email, phone],
    [industry],
    [helpMsg],
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    [company, website],
    // [consent],
  ],
  {
    required:
      lang === 'zh'
        ? ['email', 'firstName', 'company', 'industry']
        : ['email', 'firstName', 'lastName', 'company', 'industry'],
    buttonText: text.title.sale,
    submitText: text.submitText.sale,
  }
)
const visitForm = generateForm(
  [
    [email, phone],
    [industry],
    [location],
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    [company, website],
    [comment],
  ],
  {
    required:
      lang === 'zh'
        ? ['email', 'firstName', 'company', 'industry']
        : ['email', 'firstName', 'lastName', 'company', 'location', 'industry'],
    buttonText: text.title.visit,
    submitText: text.submitText.visit,
  }
)

const downloadForm = generateForm(
  [[email, company], [heardFromWhere], [industry], [chineseName, phone]],
  {
    required: [
      'email',
      'company',
      'industry',
      'firstName',
      'phone',
      'headerFromWhere',
    ],
    buttonText: '获取报告',
    submitText: text.submitText.visit,
  }
)
const indiaDownloadForm = generateForm(
  [
    [firstName, lastName],
    [industry, company],
    [email, phone],
  ],
  {
    required: ['email', 'company', 'firstName', 'lastName', 'phone'],
    buttonText: 'Download',
    submitText: text.submitText.visit,
  }
)

const bookForm = generateForm(
  lang === 'zh'
    ? [
        [chineseName, company],
        [phone, email],
        [job, industry],
        [eventName],
        [hasOverseas],
        [comment],
      ]
    : [
        [email, phone],
        [industry],
        [eventName],
        [firstName, lastName],
        [company, website],
        [comment],
        // [consent],
      ],
  {
    required:
      lang === 'zh'
        ? ['email', 'firstName', 'company', 'industry', 'overseas']
        : ['email', 'firstName', 'lastName', 'company', 'industry'],
    buttonText: text.title.book,
    submitText: text.submitText.book,
  }
)
const zgaLaunch = generateForm(
  [
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    [industry],
    [company, email],
    [job, phone],
    [invitedBy],
  ],
  {
    required:
      lang === 'zh'
        ? ['email', 'firstName', 'company', 'industry']
        : ['email', 'firstName', 'lastName', 'company', 'industry'],
    buttonText: 'Save a Seat',
    submitText: text.submitText.book,
  }
)

const indiaLPForm = generateForm(
  [[name], [industry], [company], [role], [email, phone]],
  {
    required: ['email', 'name', 'company', 'email', 'phone', 'industry'],
    buttonText: text.buttonText.submit,
    submitText: text.submitText.sale,
  }
)
const hollywoodForm = generateForm(
  [[firstName], [lastName], [email], [company], [job]],
  {
    required: ['email', 'firstName', 'lastName', 'company', 'job'],
    buttonText: text.buttonText.submit,
    submitText:
      'Thank you for registering! We look forward to seeing you on October 16!',
  }
)
const cookieForm = generateForm(
  [
    [email, phone],
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    [
      {
        type: 'item',
        id: 'consent',
        componentType: {
          type: 'consent',
          params: {
            label: 'Do Not Sell My Personal Information',
          },

          // content: Checkbox,
        },
      },
    ],
  ],
  {
    required:
      lang === 'zh'
        ? ['email', 'firstName', 'phone']
        : ['email', 'firstName', 'lastName', 'phone'],
    buttonText: text.title.cookie,
    submitText: text.submitText.cookie,
    canNotSubmitWhen: (form) => form.getField('consent') === false,
  }
)

const sdnLpForm = generateForm(
  [
    lang === 'zh' ? [chineseName] : [firstName, lastName],
    [industry],
    [company, email],
    [
      getInputObj({ id: 'a', label: 'A Location' }),
      getInputObj({ id: 'z', label: 'Z Location' }),
    ],
    [getInputObj({ id: 'bandwidth', label: 'Bandwidth' }), job],
    [phone],
  ],
  {
    buttonText: 'Request a quote',
    submitText: text.submitText.partner,
    required:
      lang === 'zh'
        ? ['company', 'email', 'firstName', 'industry']
        : ['company', 'email', 'firstName', 'lastName', 'industry'],
  }
)
const formConfig = {
  consultation: consultationForm,
  partner: partnerForm,
  visit: visitForm,
  request: requestForm,
  sale: saleForm,
  cookie: cookieForm,
  support: supportForm,
  book: bookForm,
  lp: lpForm,
  zgaLaunch,
  ebook: ebookForm,
  indiaLp: indiaLPForm,
  sdnLp: sdnLpForm,
  indiaDownload: indiaDownloadForm,
  download: downloadForm,
  hollywoodForm,
}
export default formConfig
