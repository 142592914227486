import React from "react"
import "./index.less"
const Drop = ({
  color = "#fff",
  className = "",
  // bgColor = "white",
  size = "m",
  direction = "down",
  style = {},
  width = 48,
}: any) => {
  const lMap = {
    m: 36,
    l: 64,
    xl: 120,
  }
  // const directtionMap = {
  //   down: [
  //     [0, 1, 0, 0],
  //     [1, 0, 0, 0],
  //   ],
  //   up: [
  //     [0, 0, 1, 0],
  //     [0, 0, 0, 1],
  //   ],
  //   left: [
  //     [0, 0, 1, 0],
  //     [0, 1, 0, 0],
  //   ],
  //   right: [
  //     [0, 0, 0, 1],
  //     [1, 0, 0, 0],
  //   ],
  // }
  const radius = lMap[size]
  // const _style = (matrix: any) => {
  //   return {
  //     borderRadius: matrix.map(num => `${num * radius}px`).join(" "),
  //     height: `${radius / 2}px`,
  //     background: bgColor,
  //     // width: radius
  //     width: `${radius}px`,
  //     // background
  //   }
  // }
  return (
    <svg
      style={style}
      width={width}
      height={width / 2}
      viewBox="0 0 48 24"
      className={`drop ${direction} ${className}`}
    >
      <path
        d="M48 0C34.745 0 24 10.745 24 24l-.003-.397C23.785 10.531 13.122 0 0 0z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}
export default Drop
