import React from "react"
import classnames from "../../../utils/classnames"

import Icon from "../icon"
import "./index.less"

type CheckboxProps = {
  checked: boolean
  label?: React.ReactNode
  disabled?: boolean
  className?: string
  style?: React.CSSProperties
  size?: "m" | "s"
  onChange?: (value: boolean) => any
}

const Checkbox = ({
  checked,
  label,
  disabled = false,
  className,
  onChange,
  size,
  style,
}: CheckboxProps) => {
  return (
    <div
      className={classnames("zen-checkbox", className, {
        disabled,
      })}
      onClick={() => {
        !disabled && onChange && onChange(!checked)
      }}
    >
      <i
        className={classnames("check", {
          active: checked,
          check__s: size === "s",
        })}
        style={label ? style : { marginRight: 0, ...style }}
      >
        {checked ? (
          <img src={"/checkbox-check.svg"} alt="check" />
        ) : (
          <span>&nbsp;</span>
        )}
      </i>
      {label}
    </div>
  )
}

export default Checkbox
