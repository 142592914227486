import React, { useEffect, useState } from 'react'
import { ContactPopButton, SupportPopButton } from '../form-biz'
import {
  MailOutlined,
  ArrowUpOutlined,
  CommentOutlined,
} from '@ant-design/icons'
import { lang } from '../../utils'
import './index.less'
const loadText = require('src/utils').loadText
const { btn, email } = loadText('headerAndFooter')
export default function Index() {
  const [show, setShow] = useState(false)
  useEffect(() => {
    setShow(window.scrollY > 1000)
    window.addEventListener('scroll', (e) => {
      setShow(window.scrollY > 1000)
    })
  }, [])
  return (
    <div
      className="footer-nav-btn"
      style={
        lang === 'zh'
          ? {
              bottom: '60px',
            }
          : {}
      }
    >
      <div className="contact-btn">
        <div className="contact-btn-hover-container">
          <div className="contact-btn-hover">
            <div className="item">
              <SupportPopButton buttonClassName="form-btn" source="contactUs">
                <div className="name">
                  <MailOutlined className="icon-mail" />
                  {btn.support}
                </div>
              </SupportPopButton>

              {/* <div>
                <a href={`mailto:${email.support}`}>{email.support}</a>
              </div> */}
            </div>
            <div className="item">
              <ContactPopButton buttonClassName="form-btn" source="contactUs">
                <div className="name">
                  <MailOutlined className="icon-mail" />
                  {btn.sale}
                </div>
              </ContactPopButton>

              {/* <div>
                <a href={`mailto:${email.sale}`}>{email.sale}</a>
              </div> */}
            </div>
            {lang === 'zh' && (
              <div className="website-footer-qrcode" style={{ float: 'none' }}>
                <img src="/wecom-qrcode.png" alt="" />
                <div style={{ color: '#616e82' }}>企业微信Zen客服</div>
              </div>
            )}
          </div>
        </div>
        {/* <img src="/header/email.svg" alt="email" /> */}
        <CommentOutlined />
        {/* {btn.contact} */}
      </div>
      {show && (
        <div
          className="top-btn"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        >
          <ArrowUpOutlined />
        </div>
      )}
    </div>
  )
}
