import React from 'react'
import './index.less'
import { Button, Input } from '../ui'
import { Link as GLink } from 'gatsby'
import { useEmailForm } from '../../components/form'
import { lang } from '../../utils'
import FooterNavBtn from './FooterNavBtn'
const loadText = require('src/utils').loadText
const { subject, footer: footerText } = loadText('headerAndFooter')

const url = loadText('url')

const menus = [
  [
    {
      name: subject.product,
      children:
        lang === 'zh'
          ? [
              url.gia,
              url.bmc,
              url.edcs,
              url.sdn,
              // url.sdwan,
              url.ipt,
              // url.gia,
              // url.rhs,
            ]
          : [url.bmc, url.edcs, url.sdn, url.sdwan, url.ipt, url.gia, url.cdn],
    },
    {
      name: subject.industry,
      children:
        lang === 'zh'
          ? [url.hybridCloud, url.gaming, url.media, url.cloudServiceProvider]
          : [
              url.hybridCloud,
              url.gaming,
              url.media,
              url.cloudServiceProvider,
              url.blockchain,
            ],
    },
  ],
  [
    {
      name: subject.emergingMarket,
      children: lang === 'zh' ? [url.sea] : [url.china, url.sea],
    },
    {
      name: subject.partner,
      children: [
        url.solutionsPartner,
        url.masterAgentPartner,
        url.channelPartner,
      ],
    },
    {
      name: subject.company,
      children:
        lang === 'zh'
          ? [
              url.aboutUs,
              // url.profiles,
              url.join,

              url.legal,

              url.ethics,
              url.contact,
            ]
          : [
              url.aboutUs,
              // url.profiles,
              // url.event,
              // url.blog,
              url.join,
              url.contact,
              url.legal,
              url.ethics,
              url.doNotSell,
            ],
    },
  ],
  [
    {
      name: subject.resource,
      children:
        lang === 'zh'
          ? // ? [url.downloads, url.customerStories, url.press, url.event, url.blog]
            [url.downloads, url.customerStories, url.press, url.event]
          : [
              url.whatsNew,
              url.blog,
              url.press,
              url.event,
              url.customerStories,
              url.insider,
              // url.support,
            ],
    },
  ],
]
function deleteCookies() {
  var theCookies = document.cookie.split(';')
  for (var i = 0; i < theCookies.length; i++) {
    const key = theCookies[i].split('=')[0]
    if (key !== 'consent') {
      document.cookie =
        key + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
  }
}
const legalAlias = ['Terms of Use', 'Cookie Policy', 'Privacy', 'Copyright']
// const isDevelopment =
//   typeof window !== "undefined" && window.location.hostname === "localhost"
export default function Index({ isWidget = false }: { isWidget?: boolean }) {
  const {
    val,
    error,
    onChange,
    canSubmit,
    loading,
    onSubmit,
    ButtonWrap,
  } = useEmailForm({ source: 'footer' })
  const Copyright = ({ className }) => (
    <div className={className}>
      © {new Date().getFullYear()} {footerText.copyright}
      {lang === 'zh' && (
        <span>
          |{' '}
          <a href="https://beian.miit.gov.cn/" target="_blank">
            沪ICP备2022004871号-2
          </a>
        </span>
      )}
    </div>
  )
  const Link = isWidget
    ? ({ to, children }: any) => (
        <a href={`https://www.zenlayer.com${to}`} target="_blank">
          {children}
        </a>
      )
    : GLink
  return (
    <div className="website-footer">
      {!isWidget && <FooterNavBtn />}
      <div className="website-footer-top page-middle">
        <div className="website-footer-top-left">
          <div>
            <div className="semi-bold font-20 title" style={{ color: '#fff' }}>
              {footerText.title}
            </div>
            <div
              className="font-12 subtitle"
              style={{ marginTop: '8px', color: '#B7BECB' }}
            >
              {footerText.subtitle}
            </div>
          </div>
          <div className="mt-16 input-wrapper">
            <Input
              name={footerText.email}
              value={val}
              onChange={onChange}
              error={error}
            />
          </div>
          <div>
            <ButtonWrap>
              <Button
                size="l"
                className="mt-24"
                loading={loading}
                onClick={() => {
                  if (canSubmit) {
                    onSubmit()
                  }
                }}
              >
                {footerText.subscribe}
              </Button>
            </ButtonWrap>
          </div>
          {lang === 'en' && !isWidget && (
            <a
              className="ot-sdk-show-settings"
              style={{
                marginTop: '8px',
                // color: "#fff",
                display: 'inline-block',
              }}
            >
              Cookie Settings
            </a>
          )}
        </div>
        <div className="website-footer-top-right">
          <div className="website-footer-top-rightContent">
            {menus.map((downRow) => (
              <div className="down-row">
                {downRow.map((block) => (
                  <ul key={block.name}>
                    <div className="semi-bold">{block.name}</div>
                    {block.children.map((c) => (
                      <li key={c.name} className="font-14">
                        <Link to={c.link}>{c.name}</Link>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            ))}
          </div>
        </div>
        {lang === 'zh' && (
          <div className="website-footer-qrcode">
            <img src="/wechat-qrcode.png" alt="" />
            <div>微信公众号</div>
          </div>
        )}
      </div>
      <div className="website-footer-bottom">
        <img
          className="website-footer-logo"
          src={'/logo-white.svg'}
          alt="zenlayer-bottom-logo"
        />
        <div>
          <Copyright className="website-footer-copyright" />
          <div className="website-footer-icon">
            {['facebook', 'linkedin', 'twitter'].map((key) => (
              <a href={url[key].link} target="_blank">
                <img src={`/footer/${key}.svg`} alt={key} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="website-footer-legal">
        {[
          'Terms Of Use',
          'Cookie Policy',
          'Privacy Policy',
          'Digital Millennium Copyright Act Notices',
        ].map((key, idx) => (
          <Link to={'/' + key.toLocaleLowerCase().split(' ').join('-') + '/'}>
            {legalAlias[idx]}
          </Link>
        ))}
      </div>
      <Copyright className="website-footer-copyright-m" />
    </div>
  )
}
