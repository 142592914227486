import { Modal, Button } from '../ui'
import Form from '../form'
import React, { useState } from 'react'
import './index.less'
import Drop from '../drop'
import { ajax } from 'jquery'
import { navigate } from 'gatsby'
const loadText = require('src/utils').loadText
const text = loadText('form')
import { lang } from '../../utils'
const getGeoInfo = async () => {
  const resp = await fetch('/ipInfo')
    .then((resp) => resp.json())
    .then((json) => json)
    .catch((e) => ({
      success: false,
      data: {},
    }))
  return resp
}
export const sendData = async ({ data, url, source }) => {
  const { success, data: geoData } = await getGeoInfo()
  return new Promise((resolve, reject) => {
    var formData = new URLSearchParams(window.location.search)
    Object.keys(data).forEach((key) => {
      formData.set(key, data[key] || '-')
    })
    if (success) {
      formData.set('city', geoData.city)
      formData.set('country', geoData.country)
    }
    formData.set('source', source)
    formData.set('referrer', document.referrer)
    formData.set('lang', lang === 'en' ? 'en-us' : 'zh-cn')
    if (source === 'contactUs') {
      formData.set('orgid', '00D3h0000066Hu7')
      formData.set('retURL', 'https://www.zenlayer.com')
      formData.set('recordType', '0123h000000ZB24')
      formData.set('debug', '1')
      formData.set('debugEmail', 'tim.mektrakarn@zenlayer.com')
      ajax({
        url: url + '&' + formData.toString(),
        dataType: 'jsonp',
        complete(res) {
          // const { status } = res
          resolve(undefined)
        },

        // jsonpCallback: "pardotCallback",
      })
    } else {
      ajax({
        url: url + '?' + formData.toString(),
        dataType: 'jsonp',
        jsonpCallback: 'pardotCallback',
      })
    }
    ;(window as any).pardotCallback = function (res) {
      console.log(res)
      if (res.result === 'success') {
        ;(window as any).dataLayer.push({ event: 'PardotFormSuccess', source })
        resolve(undefined)
      } else {
        ;(window as any).dataLayer.push({
          event: 'PardotFormFail',
          source,
        })

        reject()
      }
    }
  })
}

const ModalFormWrap = ({
  children,
  formTitle,
  formType,
  url,
  showConsent = true,
  source,
  defaultOpen = false,
  onSuccess = undefined,
  defaultData = {},
  onSuccessWithoutModal,
  headerRender,
}: any) => {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <div style={{ display: 'inline-block' }}>
      <Modal
        open={open}
        title={formTitle}
        width={912}
        onClose={() => {
          setOpen(false)
        }}
      >
        <div className="mt-32 modal-form-content">
          {headerRender && headerRender()}
          <Form
            defaultData={defaultData}
            type={formType}
            onSuccessWithoutModal={
              onSuccessWithoutModal
                ? () =>
                    onSuccessWithoutModal({
                      closeModal: () => {
                        setOpen(false)
                      },
                    })
                : undefined
            }
            onSuccess={() => {
              setOpen(false)
              if (onSuccess) {
                onSuccess()
              }
            }}
            source={source}
            showConsent={showConsent}
            onSubmit={async (data) => {
              await sendData({
                data,
                url,
                source,
              })
            }}
          />
        </div>
      </Modal>
      {children({ setOpen })}
    </div>
  )
}
export const CookieFormWrap = ({ children, onSuccess }) => (
  <ModalFormWrap
    source={'cookie'}
    formTitle={text.title.cookie}
    url={text.handler.cookie}
    formType="cookie"
    onSuccess={() => {
      onSuccess()
    }}
    showConsent={false}
    // defaultData={defaultData}
  >
    {({ setOpen }) => children({ setOpen })}
  </ModalFormWrap>
)
export const SaleFormWrap = ({
  source,
  children,
  defaultData,
  defaultOpen,
  headerRender,
}: any) => (
  <ModalFormWrap
    source={source}
    headerRender={headerRender}
    formTitle={text.title.book}
    defaultOpen={defaultOpen}
    url={text.handler.book}
    formType="book"
    defaultData={defaultData}
  >
    {({ setOpen }) => children({ setOpen })}
  </ModalFormWrap>
)
export const ZgaFormWrap = ({
  source,
  children,
  defaultData,
  defaultOpen,
  headerRender,
}: any) => (
  <ModalFormWrap
    headerRender={headerRender}
    source={source}
    formTitle={text.title.register}
    url={text.handler.selfHostedEvent}
    formType="zgaLaunch"
    defaultOpen={defaultOpen}
    defaultData={defaultData}
  >
    {({ setOpen }) => children({ setOpen })}
  </ModalFormWrap>
)
export const ConsultationFormWrap = ({ source, children }: any) => (
  <ModalFormWrap
    formType="consultation"
    source={source}
    formTitle={text.title.consultation}
    url={text.handler.consultation}
  >
    {({ setOpen }) => children({ setOpen })}
  </ModalFormWrap>
)
export function FormButton({
  children,
  buttonStyle = {},
  formTitle,
  url,
  buttonWidth,
  defaultData = {},
  defaultOpen,
  buttonClassName,
  formType,
  onSuccessWithoutModal,
  onSuccess,
  source = '',
}: any) {
  return (
    <ModalFormWrap
      onSuccessWithoutModal={onSuccessWithoutModal}
      formTitle={formTitle}
      defaultData={defaultData}
      url={url}
      defaultOpen={defaultOpen}
      formType={formType}
      onSuccess={onSuccess}
      source={source}
    >
      {({ setOpen }) => (
        <Button
          className={buttonClassName}
          width={buttonWidth}
          size="l"
          style={buttonStyle}
          onClick={() => {
            setOpen(true)
          }}
        >
          {children}
        </Button>
      )}
    </ModalFormWrap>
  )
}

function PageFormWrapper({ title, description, children }) {
  return (
    <div className="page-form">
      <div className="page-form-header">
        <div className="page-form-header-content full-bg  cover-1920">
          <div className="font-36 semi-bold mobile-padding">{title}</div>
          <div
            className="font-20 mt-24 block-middle mobile-padding"
            style={{ maxWidth: '1168px' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
      <div style={{ background: '#F5F9FF' }}>
        <div className="page-form-content-bg full-bg ">
          <div className="page-form-formWrapper page-middle">
            <Drop
              color="rgb(88,191,253)"
              size="l"
              style={{ top: '0px' }}
              className="only-desktop"
            />
            <div>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
type ButtonProps = {
  children: JSX.Element | string
  buttonClassName?: string
  buttonWidth?: number | string
  source: string
  onSuccess?: any
  defaultData?: any
}
export function ConsultationPopButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
  onSuccess,
}: ButtonProps) {
  return (
    <FormButton
      formType="consultation"
      source={source}
      buttonWidth={buttonWidth}
      formTitle={text.title.consultation}
      buttonClassName={buttonClassName}
      url={text.handler.consultation}
      onSuccess={onSuccess}
    >
      {children}
    </FormButton>
  )
}
export function LpPopButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
}: ButtonProps) {
  return (
    <FormButton
      formType="lp"
      source={source}
      buttonWidth={buttonWidth}
      formTitle={text.title.consultation}
      buttonClassName={buttonClassName}
      url="https://go.zenlayer.com/l/879902/2021-04-19/3v5dj"
    >
      {children}
    </FormButton>
  )
}
export function PartnerPopButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
}: ButtonProps) {
  return (
    <FormButton
      formType="partner"
      buttonWidth={buttonWidth}
      formTitle={text.title.partner}
      buttonClassName={buttonClassName}
      source={source}
      url={text.handler[source]}
    >
      {children}
    </FormButton>
  )
}
export function ZgaLaunchPopButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
}: ButtonProps) {
  return (
    <FormButton
      formType="zgaLaunch"
      buttonWidth={buttonWidth}
      defaultData={{ eventName: 'Zenlayer Accelerate 2021' }}
      formTitle={'Register for the event'}
      buttonClassName={buttonClassName}
      source={source}
      url={text.handler.selfHostedEvent}
    >
      {children}
    </FormButton>
  )
}

export function SupportPopButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
}: ButtonProps) {
  return (
    <FormButton
      formType="support"
      formTitle={text.title.support}
      buttonWidth={buttonWidth}
      url={text.handler.support}
      source={source}
      buttonClassName={buttonClassName}
    >
      {children}
    </FormButton>
  )
}
// export function SupportPopButton({
//   children,
//   buttonClassName,
//   buttonWidth,
//   source,
// }: ButtonProps) {
//   return (
//     <FormButton
//       formType="support"
//       formTitle={text.title.support}
//       buttonWidth={buttonWidth}
//       url={text.handler.support}
//       source={source}
//       buttonClassName={buttonClassName}
//     >
//       {children}
//     </FormButton>
//   )
// }
export function ContactPopButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
}: ButtonProps) {
  return (
    <FormButton
      formType="sale"
      formTitle={text.title.sale}
      buttonWidth={buttonWidth}
      url={text.handler.sale}
      source={source}
      buttonClassName={buttonClassName}
    >
      {children}
    </FormButton>
  )
}
// export function EventPopButton({
//   children,
//   buttonClassName,
//   buttonWidth,
//   source,
// }: ButtonProps) {
//   return (
//     <FormButton
//       formType="book"
//       formTitle={text.title.book}
//       source={source}
//       buttonWidth={buttonWidth}
//       url={text.handler.book}
//       buttonClassName={buttonClassName}
//     >
//       {children}
//     </FormButton>
//   )
// }

export function CustomerStoryFormButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
}: ButtonProps) {
  return (
    <FormButton
      formType="consultation"
      source={source}
      formTitle={text.title.freeSchedule}
      buttonWidth={buttonWidth}
      url={text.handler.consultation}
      buttonClassName={buttonClassName}
    >
      {children}
    </FormButton>
  )
}
export function RhsFormButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
}: ButtonProps) {
  return (
    <FormButton
      formType="request"
      formTitle={text.title.request}
      url={text.handler.request}
      buttonWidth={buttonWidth}
      source={source}
      buttonClassName={buttonClassName}
    >
      {children}
    </FormButton>
  )
}
export function VisitFormButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
}: ButtonProps) {
  return (
    <FormButton
      formType="visit"
      formTitle={text.title.visit}
      url={text.handler.visit}
      buttonWidth={buttonWidth}
      source={source}
      buttonClassName={buttonClassName}
    >
      {children}
    </FormButton>
  )
}
export function BookFormButton({
  children,
  buttonClassName,
  buttonWidth,
  source,
}: ButtonProps) {
  return (
    <FormButton
      formType="book"
      formTitle={text.title.book}
      url={text.handler.book}
      buttonWidth={buttonWidth}
      source={source}
      buttonClassName={buttonClassName}
    >
      {children}
    </FormButton>
  )
}

export function PartnerPageForm({ title, description, source }) {
  return (
    <PageFormWrapper title={title} description={description}>
      <Form
        type="partner"
        onSubmit={async (data) => {
          await sendData({
            data,
            url: text.handler[source],
            source,
          })
        }}
      />
    </PageFormWrapper>
  )
}
export function ZgaLaunchPageForm({ title, description }) {
  return (
    <PageFormWrapper title={title} description={description}>
      <Form
        type="zgaLaunch"
        onSubmit={async (data) => {
          await sendData({
            data: { ...data, eventName: 'Zenlayer Accelerate 2021' },
            url: text.handler.selfHostedEvent,
            source: 'zgaLaunch',
          })
        }}
      />
    </PageFormWrapper>
  )
}
export function EbookPageForm({
  btnText,
  source,
  onSuccess,
}: {
  btnText?: string
  source?: string
  onSuccess?: Function
}) {
  return (
    <Form
      type="ebook"
      onSuccessWithoutModal={() => {
        if (onSuccess) {
          onSuccess()
        } else {
          if (lang === 'zh') {
            const link = document.createElement('a')
            const file = '边缘计算行业白皮书.pdf'
            link.href = `/${file}`
            link.download = file
            link.dispatchEvent(new MouseEvent('click'))
          } else {
            location.href =
              'https://my.visme.co/view/jwpo71ng-zenlayer-emerging-markets-ebook#s1'
          }
        }
      }}
      btnText={btnText}
      onSubmit={async (data) => {
        await sendData({
          data: { ...data },
          url: text.handler.ebook,
          source: source || 'ebook/edge-cloud-and-media-entertainment',
        })
      }}
    />
  )
}
export function DownloadPageForm({ onSuccess }: { onSuccess: Function }) {
  return (
    <Form
      type="download"
      onSuccessWithoutModal={() => {
        onSuccess()
      }}
      onSubmit={async (data) => {
        await sendData({
          data: { ...data },
          url: 'https://go.zenlayer.com/l/879902/2022-06-14/nsym9',
          source: 'downloads',
        })
      }}
    />
  )
}
export function DownloadFormButton({
  children,
  buttonClassName,
  buttonWidth,
  defaultData,
  onSuccess,
}: // source,
ButtonProps) {
  return (
    <FormButton
      onSuccessWithoutModal={onSuccess}
      formType="download"
      source={'downloads'}
      defaultData={defaultData}
      formTitle={'领取报告 掌握行业秘籍'}
      buttonWidth={buttonWidth}
      url={'https://go.zenlayer.com/l/879902/2022-06-14/nsym9'}
      buttonClassName={buttonClassName}
    >
      {children}
    </FormButton>
  )
}
export function DownloadFormAuto({
  defaultData,
  onSuccess,
  defaultOpen,
}: // source,
any) {
  return (
    <ModalFormWrap
      defaultOpen={defaultOpen}
      onSuccessWithoutModal={onSuccess}
      formType="download"
      source={'downloads'}
      defaultData={defaultData}
      formTitle={'领取报告 掌握行业秘籍'}
      url={'https://go.zenlayer.com/l/879902/2022-06-14/nsym9'}
    >
      {() => null}
    </ModalFormWrap>
  )
}

export function LpPageForm({ title, description, source }) {
  return (
    <PageFormWrapper title={title} description={description}>
      <Form
        type="lp"
        onSubmit={async (data) => {
          await sendData({
            data,
            url: text.handler[source],
            source,
          })
        }}
      />
    </PageFormWrapper>
  )
}
export function VisitPageForm({ title, description, source }) {
  return (
    <PageFormWrapper title={title} description={description}>
      <Form
        type="visit"
        onSubmit={async (data) => {
          await sendData({
            data,
            url: text.handler.visit,
            source,
          })
        }}
      />
    </PageFormWrapper>
  )
}

export function ConsultationPageForm({ title, description, source }) {
  return (
    <PageFormWrapper title={title} description={description}>
      <Form
        type="consultation"
        onSubmit={async (data) => {
          await sendData({
            data,
            url: text.handler.consultation,
            source,
          })
        }}
      />
    </PageFormWrapper>
  )
}
export function SaleForm({ source }) {
  return (
    <Form
      type="sale"
      onSubmit={async (data) => {
        await sendData({
          data,
          url: text.handler.sale,
          source,
        })
      }}
    />
  )
}
