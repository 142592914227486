import React, { useState, useRef, useEffect } from "react"
import classnames from "../../../utils/classnames"
import { Tooltip } from "antd"
import "antd/lib/tooltip/style/css"
import { CloseCircleFilled } from "@ant-design/icons"
import "./index.less"

interface Props {
  value?: string // input的value
  onChange?: (value: string) => void // 输入时触发的回调函数，一般用来设置
  name: string // placeholder
  width?: number | string // 宽度
  type?: string // 类型，即input的type属性
  disabled?: boolean // 是否disable
  readonly?: boolean // 是否只读
  touched?: boolean // 设置input的touch状态
  allowClear?: boolean // 是否允许一键清空value
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void // 键盘事件回调函数
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void // 失去焦点回调函数
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void // 获取焦点回调函数
  className?: string // 自定义class
  error?: string // 错误文字，不传则不显示
  size?: "m" | "l" // 输入框尺寸
  suffix?: (value: string) => React.ReactNode // 后缀内容
}
export const NameInput = ({ value, onChange, name, type }: any) => (
  <div className="name-input">
    <label>{`${name} :`}</label>
    <input
      type={type}
      value={value}
      style={{ width: "60px" }}
      onChange={e => {
        onChange(e.target.value)
      }}
    />
  </div>
)
export default function Input({
  type,
  value = "",
  onChange,
  name,
  width = "100%",
  disabled = false,
  readonly = false,
  // touched = false,
  allowClear = false,
  onBlur,
  error,
  size,
  onFocus,
  className,
  onKeyDown,
  suffix,
}: Props) {
  const inputEl = useRef<HTMLInputElement>(null)
  const [shrink, setShrink] = useState<boolean>(false)
  const [isFocus, setFocus] = useState<boolean>(false)
  // const [isTouched, setTouched] = useState<boolean>(touched);
  const usable = !disabled && !readonly
  // error = isTouched ? error : '';
  const handleInput = (v: string) => {
    // setTouched(true);
    onChange && onChange(v)
  }
  // useEffect(() => {
  //   if (touched) {
  //     setTouched(true);
  //   }
  // }, [touched]);
  useEffect(() => {
    if (value !== "") {
      setShrink(true)
    }
    if (error && !shrink) {
      setShrink(true)
    }
  }, [value, error])
  return (
    <div
      className={classnames("zen-input", className, {
        "zen-input__error": !!error,
        "zen-input__disabled": disabled,
        "zen-input__l": size === "l",
      })}
      onClick={() => {
        if (usable) {
          ;(inputEl.current as HTMLInputElement).focus()
        }
      }}
      style={{ width }}
    >
      <div
        className={classnames("zen-input-label", "zen-input-label__animated", {
          "zen-input-label__shrink": shrink,
          "zen-input-label__disabled": disabled,
          "zen-input-label__readonly": readonly,
          "zen-input-label__error": !!error,
          "zen-input-label__l": size === "l",
          "zen-input-label__shrink__l": size === "l" && shrink,
        })}
      >
        {name}
      </div>
      <Tooltip placement="top" title={error} visible={isFocus && !!error}>
        <input
          className={classnames("zen-input-content", {
            "zen-input-content__l": size === "l",
          })}
          value={value}
          onChange={e => {
            handleInput(e.target.value)
          }}
          type={type}
          onKeyDown={onKeyDown}
          ref={inputEl}
          onBlur={e => {
            if (readonly) {
              return
            }
            setFocus(false)
            // setTouched(true);
            if (onBlur) {
              onBlur(e)
            }
            setShrink(value !== "" || !!value || !!error)
          }}
          onFocus={e => {
            if (readonly) {
              return
            }
            setFocus(true)
            if (onFocus) {
              onFocus(e)
            }
            usable && setShrink(true)
          }}
          disabled={disabled}
          readOnly={readonly}
          autoComplete="new-password"
        />
      </Tooltip>
      {usable && allowClear && value && (
        <CloseCircleFilled
          className="icon-clear"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            handleInput("")
            setShrink(false)
          }}
        />
      )}
      {suffix && suffix(value)}
    </div>
  )
}
