import React from "react"
import { Pagination } from "antd"
import { PaginationProps as AntPaginationProps } from "antd/es/pagination"
// import classnames from '@/utils/classnames';
import "antd/lib/pagination/style/css"

import classnames from "../../../utils/classnames"

import "./index.less"

interface Props extends AntPaginationProps {
  className?: string
  style?: React.CSSProperties
  pageNum?: number
}

const ZenPagination = ({ className, style, pageNum, ...rest }: Props) => (
  <div className={classnames("zen-pagination", className)} style={style}>
    <Pagination
      {...rest}
      current={rest.current || pageNum}
      showSizeChanger={false}
    />
  </div>
)

export default ZenPagination
