import * as React from "react"
// import Icon from "@/component/share/icon"
import classnames from "../../../utils/classnames"
import { LoadingOutlined } from "@ant-design/icons"
import "./index.less"

interface ButtonProps {
  text?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any
  type?: "primary" | "normal" | "alert"
  size?: "xs" | "s" | "m" | "l" | "xl"
  icon?: string
  children?: React.ReactNode
  width?: number | string
  loading?: boolean
  disabled?: boolean
  className?: string
  style?: React.CSSProperties
  outline?: boolean
}

const Button = ({
  type = "primary",
  size = "m",
  icon = "",
  children,
  onClick,
  width,
  loading = false,
  disabled = false,
  className,
  style,
  outline = false,
}: ButtonProps) => (
  <button
    onClick={e => !loading && onClick && onClick(e)}
    className={classnames(
      `button button-${outline ? type + "__outline" : type} button-${size}`,
      className,
      {
        loading,
      }
    )}
    style={{ ...style, width }}
    disabled={disabled}
  >
    <span className="button__inner">
      <div
        style={{
          marginRight: loading ? "8px" : 0,
        }}
      >
        {loading && <LoadingOutlined />}
      </div>
      {children && <span className="button-text">{children}</span>}
    </span>
  </button>
)

export default Button
