/**
 * 对话框
 */
import React from "react"
// import Button from "../button"

import classnames from "../../../utils/classnames"
import "antd/lib/modal/style/css"

import { Modal } from "antd"
import "./index.less"

type ModalProps = {
  open: boolean // 是否显示
  title?: React.ReactNode // 标题
  children?: React.ReactNode // 内容
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => any // 关闭时的回调函数
  width?: number // 自定义宽度
  className?: string // 自定义对话框class
  closable?: boolean // 是否显示右上角的关闭按钮
  keyboard?: boolean // 是否支持键盘 esc 关闭
  maskClosable?: boolean // 点击蒙层是否允许关闭
}

const ZenModal = ({
  open,
  title,
  children,
  onClose,
  width = 500,
  className,
  closable,
  keyboard,
  maskClosable,
}: ModalProps) => {
  return (
    <Modal
      visible={open}
      width={width}
      wrapClassName={classnames("zen-modal", className)}
      maskStyle={{ backgroundColor: "rgba(245, 249, 255, 0.8)" }}
      z-index={1000}
      footer={null}
      onCancel={onClose}
      closable={closable}
      keyboard={keyboard}
      maskClosable={maskClosable}
      destroyOnClose
    >
      <div className="zen-modal-title">{title}</div>
      {children}
    </Modal>
  )
}

export default ZenModal
