import React, { useState } from 'react'
import Input from '../input'
import { Select } from 'antd'
import { BranchesOutlined } from '@ant-design/icons'
// import {Textarea} from 'antd'

const { Option } = Select
const defaultForObj = (arr: any, value: any) =>
  arr.reduce((acc: any, cur) => {
    acc[cur.id] = value
    return acc
  }, {})
const getError = (value: any, data: any, validators = []) => {
  let error = ''
  for (let i = 0; i < validators.length; i++) {
    try {
      validators[i](value, data)
    } catch (e) {
      error = e
      break
    }
  }
  return error
}
const FormPlus = ({ children, config, defaultData, submit }: any) => {
  const [data, setData] = useState({
    ...defaultForObj(config.form, ''),
    ...defaultData,
  })
  const getInitError = () => {
    return config.form.reduce((acc: any, cur) => {
      acc[cur.id] = getError(
        '',
        { ...defaultForObj(config.form, ''), ...defaultData },
        config.customRule[cur.id] || []
      )
      return acc
    }, {})
  }
  const [errors, setErrors] = useState(getInitError())

  const [touch, setTouch] = useState(defaultForObj(config.form, false))
  const reset = () => {
    setTouch(defaultForObj(config.form, false))
    setData({
      ...defaultForObj(config.form, ''),
      ...defaultData,
    })
    setErrors(getInitError())
  }
  return (
    <div>
      <div className="form-content-body">
        {config.form.map(({ id, customClassName, componentType }: any) => {
          const error = touch[id] ? errors[id] : ''
          const onChange = (value: any) => {
            setData((state) => ({ ...state, [id]: value }))
            setTouch((state) => ({ ...state, [id]: true }))
            setErrors((state) => ({
              ...state,
              [id]: getError(value, data, config.customRule[id]),
            }))
          }
          let Comp = null
          const value = data[id]
          if (componentType.type === 'input') {
            Comp = (
              <Input
                {...componentType.params}
                onChange={(value) => onChange(value)}
                value={value}
              />
            )
          } else if (componentType.type === 'select') {
            // console.log(componentType)
            Comp = (
              <Select
                style={{ width: '100%' }}
                {...componentType.params}
                onChange={onChange}
                // showSearch={true}
                value={value === '' ? undefined : value}
              >
                {config.sourceData[id].data.map(({ value, label }) => (
                  <Option value={value}>{label}</Option>
                ))}
              </Select>
            )
          } else if (componentType.type === 'textarea') {
            Comp = (
              <textarea
                className="zen-textarea"
                {...componentType.params}
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
            )
          } else {
            const _Comp = componentType.content
            Comp = (
              <_Comp
                sourceData={config.sourceData[id].data}
                value={value || []}
                onChange={onChange}
              />
            )
            // console.log(config, componentType)
            // return
          }
          return (
            <div
              className={`${customClassName} form-plus-item`}
              style={{ position: 'relative' }}
            >
              {Comp}
              {error && (
                <div
                  style={{
                    color: 'red',
                    position: 'absolute',
                    bottom: '-24px',
                  }}
                >
                  {error}
                </div>
              )}
            </div>
          )
        })}
        {children}
      </div>
      <div>
        {React.cloneElement(submit, {
          reset,
          canSubmit: () => {
            console.log('can submit')
            let hasError = false
            setTouch(defaultForObj(config.form, true))
            Object.keys(errors).forEach((key) => {
              if (errors[key]) {
                hasError = true
              }
            })
            return !hasError
          },
          data,
        })}
      </div>
    </div>
  )
  // return <div>{JSON.stringify(props)}</div>
}

export default FormPlus
