import _Button from "./button"
import _Tab from "./tab"
// import "antd/dist/antd.css"
import _Input from "./input"
import _Pagination from "./pagination"
import _Checkbox from "./checkbox"
import _Modal from "./modal"
import _FormPlus from "./formplus"
// import React from "react"

export const Button = _Button
export const Input = _Input
export const Tab = _Tab
export const Pagination = _Pagination

export const FormPlus = _FormPlus

export const Checkbox = _Checkbox
export const Modal = _Modal
